<template>
    <main class="main-container">
        <div class="wrapper">
            <div class="text-element text-center">
                <h1>DiscAPI by HiddenStorm (DOCS COMING SOON)</h1>
                <h3>An easy to use Python Library to make all kinds of unique discord bots.</h3>
            </div>
            <div class="text-element">
                <h1>Getting started</h1>
                <p>If this is your first time using this library, start by reading those topics:</p>
                <div class="flex justify-start items-center gap-2">
                    <a class="button">Introductions</a>
                    <a class="button">Example</a>
                </div>
            </div>
        </div>
    </main>
</template>